import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Button from './Button';

const Navbar: React.FC = () => (
  <nav className="w-full flex justify-between bg-white h-36 items-center px-4 border-b shadow-sm top-0 sticky z-50">
    <div>
      <a href="/">
        <StaticImage
          src="../images/logos/logo11d.png"
          alt="Debt Management Law"
          height={125}
          placeholder="tracedSVG"
        />
      </a>
    </div>
    <div>
      <a href="https://debtmanagementlaw.fincocms.org/" className="mr-4 text-gray-900 hidden md:inline-block">Client Portal Login</a>
      <a href="#get-started">
        <Button>Get Started</Button>
      </a>
    </div>
  </nav>
);

export default Navbar;
