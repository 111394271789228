import { LocationMarkerIcon, PhoneIcon } from '@heroicons/react/solid';
import React from 'react';

const Footer: React.FC = () => (
  <footer className="px-8 bg-gray-700 mt-12 text-gray-300">
    <div className="flex py-6">
      <div className=" max-w-xs">
        <h1 className="font-bold mb-2">The Chisari Law Group for Debt Management</h1>
        <p className="text-sm">
          We are a full service Law Firm specializing in Bankruptcy, Debt Management,
          and Debt Settlement Plans
        </p>

        <div className="mt-3">
          <a href="tel:1-800-525-6199">
            <p className="flex items-center">
              <PhoneIcon className="h-3 w-3" />
              <span className="ml-1">1-800-525-6199</span>
            </p>
          </a>

          <p className="flex items-center">
            <LocationMarkerIcon className="h-3 w-3" />
            <span className="ml-1">108 New South Rd, Hicksville, NY 11801</span>
          </p>
        </div>
      </div>
    </div>
    <div className="border-gray-600 border-t py-6">
      <p className="text-center text-sm mt-4">&copy; Debt Management Law. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
