import React from 'react';

interface Props {
    className?: string;
}

const SectionHeader: React.FC<Props> = ({ children, className }) => (
  <h2 className={`text-3xl mb-4 font-extrabold tracking-tight leading-8 text-gray-900 ${className}`}>
    {children}
  </h2>
);

export default SectionHeader;
