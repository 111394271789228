import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const MainLayout: React.FC = ({ children }) => (
  <div className="text-gray-900">
    <Navbar />

    {children}

    <Footer />
  </div>
);

export default MainLayout;
