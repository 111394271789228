import React from 'react';
import Spinner from './icons/Spinner';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  outline?: boolean,
  isLoading?: boolean
}

const Button: React.FC<Props> = ({
  children,
  outline,
  isLoading = false,
  ...rest
}) => (
  <button
    type="button"
    className={` ${outline ? 'bg-transparent border-sky-500 text-sky-500 hover:bg-gray-100' : 'bg-sky-500 border-transparent text-white hover:bg-sky-600'} font-medium py-3 px-8 rounded border-2 shadow`}
    {...rest}
  >
    <span className="flex items-center">
      {isLoading && <Spinner className="h-5 w-5 animate-spin mr-2" />}
      {children}
    </span>
  </button>
);

export default Button;
