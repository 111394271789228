import React from 'react';

interface Props {
    className?: string;
}

const PreHeader: React.FC<Props> = ({ children, className }) => (
  <p className={`text-sky-500 uppercase mb-2 text-md font-semibold tracking-wide text-base ${className}`}>
    {children}
  </p>
);

export default PreHeader;
